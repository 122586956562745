/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  RemoveOrgUnitMutationVariables,
  useRemoveOrgUnitMutation,
  UpdateOrgUnitMutationVariables,
  UpdateOrgUnitMutationOptions,
  useUpdateOrgUnitMutation,
  UpdateOrgUnitV2,
} from "~/generated/graphql";
import { removeFromApolloCache } from "~/tools";

export function useRemoveOrgUnit(
  options?: Parameters<typeof useRemoveOrgUnitMutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveOrgUnitMutation({
    ...options,
  });

  const removeOrgUnit = async (variables: RemoveOrgUnitMutationVariables) => {
    await remove({ variables });

    removeFromApolloCache(client.cache, { item: `OrgUnitV2:${variables.id}` });
  };

  return [removeOrgUnit, { client, ...rest }] as const;
}

export type UpdateOrgUnit = UpdateOrgUnitV2;

export function useUpdateOrgUnit(options?: UpdateOrgUnitMutationOptions) {
  const [update, { client, ...rest }] = useUpdateOrgUnitMutation({
    ...options,
  });

  // `updateOrgUnitMutation` auto-updates the cache of `OrgUnitV2`
  // manual update of `OrgUnitWithChildren is required`
  const updateOrgUnit = async (variables: UpdateOrgUnitMutationVariables) => {
    const { data } = await update({ variables });

    client.cache.modify({
      id: client.cache.identify({
        __typename: "OrgUnitWithChildren",
        id: variables.nid,
      }),
      fields: {
        name: (existingName) =>
          data?.updateOrgUnitV4?.orgUnit?.name ?? existingName,
        description: (existingDescription) =>
          data?.updateOrgUnitV4?.orgUnit?.description ?? existingDescription,
        type: (existingType) =>
          data?.updateOrgUnitV4?.orgUnit?.type ?? existingType,
        contact: (existingContact) =>
          data?.updateOrgUnitV4?.orgUnit?.contact ?? existingContact,
      },
    });

    return { data };
  };

  return [updateOrgUnit, { client, ...rest }] as const;
}
