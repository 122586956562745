/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { DateRange, EstatePeriod, Granularity } from "~/components";
import { useQueryState } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { mapEstatePeriodToBillingPeriod } from "../../helpers";
import { getStartDate, getEndDate } from "../helpers";
import { EstateChartsQueryState } from "../types";
import { getBillingPeriod, mapEstatePeriod } from "./helpers";

export function useEstateChartParameters() {
  const { state, updateQueryState } = useQueryState<EstateChartsQueryState>();
  const { isPreviousBillingPeriod } = useBillingPeriod();

  const [period, setPeriod] = useState<EstatePeriod>(
    getBillingPeriod(state.billingPeriod)
  );

  const [search, setSearch] = useState("");

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [range, setRange] = useState<DateRange | undefined>(undefined);

  const granularity = state.granularity ?? Granularity.MONTHS;

  const handleGranularityChange = (granularitySelection: Granularity) => {
    updateQueryState({
      granularity: granularitySelection,
    });
  };

  const clearRange = () => {
    setRange(undefined);
  };

  const handlePeriodChange = (
    selectedPeriod: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    setPeriod(selectedPeriod);
    if (selectedRange) {
      setRange(selectedRange);
    }

    updateQueryState({
      periodStart: getStartDate(selectedPeriod, selectedRange),
      periodEnd: getEndDate(selectedPeriod, selectedRange),
      billingPeriod: mapEstatePeriodToBillingPeriod(selectedPeriod),
    });
  };

  useEffect(() => {
    if (period !== EstatePeriod.RANGE) {
      handlePeriodChange(mapEstatePeriod(isPreviousBillingPeriod));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousBillingPeriod]);

  return {
    period,
    granularity,
    range,
    handleGranularityChange,
    handlePeriodChange,
    search,
    setSearch,
    clearRange,
    isDataLoading,
    setIsDataLoading,
  };
}
