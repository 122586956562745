/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import {
  EstateV2Filter,
  Provider,
  SearchSortField,
  SearchSortOrder,
  useMetricResourceQuery,
} from "~/generated/graphql";
import { LoaderWrap, NoData, Pagination, ReactTable } from "~/components";
import { EstateData } from "~/components/EstateRecordsList";
import {
  KUBERNETES_METRICS_COLUMNS,
  PROVIDER_ICON_COLUMN,
  STATUS_ICON_COLUMN,
} from "~/components/Tables/Columns";
import {
  estateV2FilterFields,
  useCurrency,
  useEstateSort,
  useFilteredQueryState,
} from "~/hooks";
import { isNotEmpty, isNotNil } from "~/tools";
import { generateRowData } from "~/views/estate/helpers";
import { PodSort } from "./PodSort";

const POD_TYPES = ["KUBERNETES/Pod", "OPENSHIFT/Pod"];

type SortingProp = {
  field?: SearchSortField;
  order?: SearchSortOrder;
};

type QueryState = EstateV2Filter &
  SortingProp & {
    page: number;
    limit: number;
    tags: string[];
    tab: number;
    query: string;
  };

type Props = {
  details: Detail[];
  provider: Provider | undefined;
};

export function PodsListTabContents({ details, provider }: Props) {
  const { currency } = useCurrency();

  const { state, updateQueryState } =
    useFilteredQueryState(estateV2FilterFields);

  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const { page, limit, field, order }: QueryState = state;

  const { sortField, sortDirection, onSortSelect, onSortOrderChange } =
    // @ts-expect-error ignored temporary after migration to TypeScript 4.9
    useEstateSort({ field, order, updateQueryState });

  const phrase =
    details.find((detail) =>
      ["Record Name", "Resource Name"].includes(detail.label)
    )?.value ?? "";

  const { data: metrics, loading } = useMetricResourceQuery({
    variables: {
      limit,
      page,
      filter: {
        account: [extractDetails(details, "Account")],
        typeSubtype: POD_TYPES,
      },
      sort: {
        field: sortField,
        order: sortDirection,
      },
      query: phrase,
    },
  });

  const count = metrics?.estateV2?.count ?? 0;

  const metricsColumns = getMetricsColumns(provider);
  const metricDetails = useMemo(() => {
    const resources = metrics?.estateV2?.resources ?? [];

    return resources.map((resource) => ({
      ...generateRowData(resource, currency),
    }));
  }, [metrics, currency]);

  return (
    <LoaderWrap inContent loading={loading}>
      <If condition={isNotEmpty(metricDetails)}>
        <Then>
          <Box boxStyle="lightGrey" spacing="spacing04">
            <FlexContainer
              justifyContent="space-between"
              alignItems="center"
              data-testid="sort-wrapper"
            >
              <PodSort
                sortField={sortField}
                sortDirection={sortDirection}
                onSortSelect={onSortSelect}
                onSortOrderChange={onSortOrderChange}
              />
            </FlexContainer>
          </Box>
          <FlexContainer marginBottom={theme.spacing.spacing02}>
            <EstateData
              count={metrics?.estateV2?.count}
              currentMonthTotal={metrics?.estateV2?.cost?.currentMonthTotal}
              recordsType="Pods"
            />
          </FlexContainer>
          <ReactTable
            striped
            columns={metricsColumns}
            data={metricDetails}
            sort={false}
          />
          <Pagination count={count} />
        </Then>
        <Else>
          <NoData message={<Text>There are no related resources.</Text>} />
        </Else>
      </If>
    </LoaderWrap>
  );
}

type Detail = {
  label: string;
  value: string;
};

function extractDetails(details: Detail[], label: string) {
  return details.find((detail) => detail.label === label)?.value ?? "";
}

const POD_STATUS_PROVIDERS = [Provider.Kubernetes, Provider.Openshift];

function isPodStatusColumnVisible(provider?: Provider) {
  return isNotNil(provider) && POD_STATUS_PROVIDERS.includes(provider);
}

function getMetricsColumns(provider?: Provider) {
  return [
    isPodStatusColumnVisible(provider)
      ? STATUS_ICON_COLUMN
      : PROVIDER_ICON_COLUMN,
    ...KUBERNETES_METRICS_COLUMNS,
  ];
}
