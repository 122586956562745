/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Item } from "./types";

type Props = {
  selectableItems: Item[];
  selectedOptions: string[];
  onMultiSelect: (ids: string[]) => void;
};

export function useSelectableListFilter({
  selectableItems,
  selectedOptions,
  onMultiSelect,
}: Props) {
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Item[]>([]);

  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setFilteredOptions(
      selectableItems.filter(
        ({ label, value }) =>
          label?.toLowerCase().includes(search) ||
          value?.toLowerCase().includes(search)
      )
    );
  }, [search, selectableItems]);

  const onApply = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems((prev) =>
        prev.filter((storedItem) => storedItem !== item)
      );
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  useEffect(() => {
    onMultiSelect(selectedItems ?? []);
  }, [selectedItems]);

  return {
    selectedItems,
    filteredOptions,
    setSearch,
    onApply,
  };
}
