/* *
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Button,
  Sidebar,
  Label,
  Input,
  Text,
  theme,
  InputPassword,
} from "@nordcloud/gnui";
import { WorkflowExternalIntegrationType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { noop } from "~/tools";
import { SlackFields } from "~/views/workflows/AddWorkflow/constants";
import { WorkflowDataType } from "../../constants";
import {
  getDefaultValues,
  WorkflowFormData,
  WorkflowFormMode,
} from "../../types";
import { getDefaultValues as getDefaultWorkflowValues } from "../../WorkflowDetails/helpers";
import { WorkflowProps } from "../../WorkflowPropsMapping";
import { MessageSwitch } from "./MessageSwitch";

type Props = {
  formMode: WorkflowFormMode;
  isOpen: boolean;
  workflow?: WorkflowProps;
  onClose: () => void;
  onSave?: () => void;
  requireToken: boolean;
};

export function SlackSettingsForm({
  formMode,
  isOpen,
  workflow,
  onClose,
  onSave = noop,
  requireToken,
}: Props) {
  const {
    register,
    trigger,
    formState: { errors },
    watch,
    resetField,
    setValue,
  } = useFormContext<WorkflowFormData>();

  const [
    slack,
    slackSubmissionSnapshot,
    selectDataComponent,
    sendDataComponents,
  ] = watch([
    "slack",
    "slackSubmissionSnapshot",
    "selectDataComponent",
    "sendDataComponents",
  ]);

  const isReadMode = formMode === "read";

  const validateInputs = async () => {
    if (sendDataComponents.includes(WorkflowExternalIntegrationType.Slack)) {
      if (selectDataComponent === WorkflowDataType.COSTS) {
        return await trigger([
          "slack.channel",
          "slack.message",
          "slack.token",
          "slack.costsOptions",
        ]);
      }

      if (selectDataComponent === WorkflowDataType.KPI) {
        return await trigger([
          "slack.channel",
          "slack.message",
          "slack.token",
          "slack.kpiOptions",
        ]);
      }

      if (selectDataComponent === WorkflowDataType.BUDGETS) {
        return await trigger([
          "slack.channel",
          "slack.message",
          "slack.token",
          "budgetsOptions.costGtBudget",
        ]);
      }

      if (selectDataComponent === WorkflowDataType.SAVING_SUGGESTIONS) {
        return await trigger([
          "slack.channel",
          "slack.token",
          "slack.savingSuggestionsOptions",
        ]);
      }
    }

    return await trigger(["slack.token", "slack.message", "slack.channel"]);
  };

  const handleSaveButtonClick = async () => {
    setValue("slack.message", undefined);

    const isValid = await validateInputs();

    if (isValid) {
      setValue("slackSubmissionSnapshot", JSON.stringify(slack), {
        shouldDirty: true,
      });
      setValue("slackSaved", true);
      onSave();
    }
  };

  const handleClose = () => {
    if (formMode === "add") {
      resetField("slack", {
        defaultValue: slackSubmissionSnapshot
          ? JSON.parse(slackSubmissionSnapshot)
          : getDefaultValues().slack,
      });
    }
    if (formMode === "edit") {
      resetField("slack", {
        defaultValue: slackSubmissionSnapshot
          ? JSON.parse(slackSubmissionSnapshot)
          : workflow && getDefaultWorkflowValues(workflow).slack,
      });
    }

    resetField("budgetsOptions.costGtBudget");
    resetField("budgetsOptions.forecastGtBudget");

    onClose();
  };

  const slackErrors = errors.slack;

  return (
    <Sidebar
      title="Slack Settings"
      isOpen={isOpen}
      width="42rem"
      onClick={handleClose}
    >
      <When condition={!isReadMode}>
        <Text size="sm" color={theme.color.text.text02}>
          Please enter the following information of your Slack account.
        </Text>
      </When>
      <FormGroup error={slackErrors?.[SlackFields.CHANNEL]}>
        <Label name="Channel" htmlFor="channel" required={!isReadMode} />
        <Input
          disabled={isReadMode}
          id="channel"
          placeholder="Slack channel id"
          {...register("slack.channel")}
        />
      </FormGroup>
      <When condition={!isReadMode}>
        <FormGroup error={slackErrors?.[SlackFields.TOKEN]}>
          <Label
            name="Authorization Token"
            htmlFor="token"
            required={requireToken}
          />
          <InputPassword
            id="token"
            disabled={isReadMode}
            placeholder="•••••••••••••••••••••••••••••••"
            showPasswordOnInit={false}
            {...register("slack.token")}
          />
        </FormGroup>
      </When>
      <MessageSwitch
        selectDataComponent={selectDataComponent}
        isReadMode={isReadMode}
        slackErrors={slackErrors}
      />
      <When condition={!isReadMode}>
        <Button
          type="button"
          mt={theme.spacing.spacing04}
          onClick={handleSaveButtonClick}
        >
          Apply
        </Button>
      </When>
    </Sidebar>
  );
}
