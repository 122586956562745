// Source: https://github.com/DataDog/rum-react-integration-examples/tree/master/src/Router

import { useMemo } from "react";
import { Route, type RouteProps } from "react-router-dom";

import { withRum } from "./RumRouteComponentWrapper";

export function RumRoute({
  children,
  component,
  render,
  ...otherProps
}: RouteProps) {
  const RumComponent = useMemo(() => {
    // this is react-router priority
    return withRum(children ?? component ?? render);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- putting `render` in dependency array will cause infinite renders
  }, [children, component, JSON.stringify(otherProps.path)]);

  return <Route {...otherProps} component={RumComponent} />;
}
