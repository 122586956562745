/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import * as queryString from "query-string";
import { When } from "react-if";
import { generatePath, useParams } from "react-router-dom";
import { Spacer, theme } from "@nordcloud/gnui";
import {
  BrickLoader,
  UniversalWrap,
  BudgetSetupNotification,
  CostTableData,
  CostVsBudgetChart,
  getColorsPalette,
  getTableColumns,
  usePeriod,
  getEndDate,
  ChartType,
  Granularity,
  Period,
} from "~/components";
import { useCurrency } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { RouteParams } from "~/views/environments/types";
import { useEnvAccumulatedCostChart } from "../hooks";
import { useEnvCostVersusBudget } from "../hooks/useEnvCostVersusBudgetChart";

type Props = {
  envId: string;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  period: Period;
  hasFooter: boolean;
  showCostTable: boolean;
  forecast: number;
};

export function EnvCostVersusBudgetView({
  envId,
  startDate,
  endDate,
  granularity,
  period,
  hasFooter,
  showCostTable,
  forecast,
}: Props) {
  const { currency } = useCurrency();
  const { currentYear } = usePeriod();
  const { application } = useParams<RouteParams>();

  const {
    envAccumulatedCostTotal,
    envAccumulatedCostTimePoints,
    envAccumulatedCostBudgetChartData,
    loading,
    error: envCostError,
  } = useEnvAccumulatedCostChart({
    envId,
    startDate,
    endDate: getEndDate(endDate),
    period,
    granularity,
  });

  const {
    envBudgetData,
    envCostVersusBudgetChartData,
    envBudgetTable,
    envBudgetTotal,
    hasBudget,
    loading: isBudgetLoading,
    error: budgetError,
  } = useEnvCostVersusBudget({
    envId,
    currentYear,
    period,
    envAccumulatedCostBudgetChartData,
    startDate,
    endDate,
    envAccumulatedCostTimePoints,
    envAccumulatedCostTotal,
  });

  const columns = useMemo(
    () =>
      getTableColumns({
        startDate,
        endDate,
        currency,
        data: envBudgetTable,
        hasFooter,
        period,
        chartType: ChartType.COST_VS_BUDGET,
        colorsPalette: getColorsPalette(ChartType.COST_VS_BUDGET),
      }),
    [currency, startDate, endDate, hasFooter, envBudgetTable]
  );

  const isLoading = loading || isBudgetLoading;
  const error = envCostError || budgetError;

  return (
    <>
      <UniversalWrap
        loaderProps={{ loading: isLoading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <Spacer height={theme.spacing.spacing02} />
        <When condition={!hasBudget}>
          <BudgetSetupNotification
            link={`${generatePath(ROUTES.applications.environment, {
              application,
              environment: envId,
            })}?${queryString.stringify({ costTab: "budget" })}`}
          />
        </When>
        <ParentSize>
          {(parent) => (
            <CostVsBudgetChart
              width={parent.width}
              height={340}
              loading={isLoading}
              data={envCostVersusBudgetChartData}
              granularity={granularity}
              startDate={startDate}
              endDate={endDate}
              budgetData={envBudgetData}
              forecastValue={forecast}
            />
          )}
        </ParentSize>
        <CostTableData
          period={period}
          startDate={startDate}
          endDate={endDate}
          tableData={envBudgetTable}
          chartType={ChartType.COST_VS_BUDGET}
          total={envBudgetTotal}
          tableError={error}
          columns={columns}
          isLoading={isLoading}
          showCostTable={showCostTable}
          hasFooter={hasFooter}
        />
      </UniversalWrap>
    </>
  );
}
