/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider, useEstateSearchQuery } from "~/generated/graphql";
import { isCloudResource } from "~/tools";
import { ProviderSpecificDetails } from "../types";

export function useEstateNidsByProviderDetails(
  provider: Provider,
  details: ProviderSpecificDetails[]
): { [providerId: string]: string } {
  const providerIDs = [...new Set(details.map(({ providerId }) => providerId))];

  const { data: searchResult } = useEstateSearchQuery({
    variables: {
      limit: providerIDs.length,
      filter: {
        provider: [provider],
        providerId: providerIDs,
        typeSubtype: [
          ...new Set(details.map(({ typeSubtype }) => typeSubtype)),
        ],
      },
    },
  });

  const records = searchResult?.estateV2?.resources ?? [];
  return Object.fromEntries(
    records
      .filter(isCloudResource)
      .filter((r) => providerIDs.includes(r.name))
      .map((r) => [r.name, r.id])
  );
}
