/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then, When } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { Currency } from "~/tools";
import { TooltipIconBox } from "../../TooltipIconBox";
import { ChartDates, CostAnalysisFields, Granularity } from "../types";
import { savingsPlanColor } from "./consts";
import { getTooltipItem } from "./StackTooltip";
import { formatDisplayDate, getRangeDate, getTooltipItemKey } from "./utils";

type Props = {
  data: {
    date: string;
  }[];
  index: number;
  granularity: Granularity;
  currency: Currency;
  colors: { [k: string]: string };
  dateRangeDates?: ChartDates[];
  areKeysByGranularity?: boolean;
};

export function AccumulatedCostTooltip({
  data,
  index,
  granularity,
  currency,
  colors,
  dateRangeDates,
  areKeysByGranularity = false,
}: Props) {
  const tooltipDisplayDate = dateRangeDates
    ? getRangeDate(dateRangeDates, data?.[index].date)
    : formatDisplayDate(data?.[index].date, granularity);

  const tooltipDate = dayjs(data?.[index].date).format(dateFormat.shortDate);
  const date = data?.find((item) => item?.date === tooltipDate);
  const isTooltipDateDayAfterToday = dayjs(tooltipDate).isAfter(dayjs(), "day");

  const entries = Object.entries(date ?? []).filter(
    ([key]) => !["date"].includes(key)
  );

  const savings = entries.find(
    ([key]) => key === CostAnalysisFields.SAVINGS_PLANS
  );
  const savingsCost = Number(savings?.[1] ?? 0);

  const mappedEntries = mapEntries(entries);
  const entriesWithSavings = isTooltipDateDayAfterToday
    ? [
        ...mappedEntries,
        [CostAnalysisFields.SAVINGS_PLANS, savingsCost.toFixed(2)],
      ]
    : mappedEntries;

  return (
    <div
      key={`tooltip-${tooltipDisplayDate.toString()}`}
      css={{ minWidth: "150px" }}
    >
      <Text m={0} size="sm" color={theme.color.text.text03}>
        {tooltipDisplayDate}
      </Text>
      {entriesWithSavings.map(([key, value]) => {
        const isDailyCost = key === CostAnalysisFields.DAILY_COST;
        const isCorrections = key === CostAnalysisFields.CORRECTION_COST;

        if (isCorrections && Number(value) === 0) {
          return null;
        }

        return (
          <FlexContainer key={key} direction="row">
            <When condition={!isDailyCost}>
              <TooltipIconBox color={getIconColor(key, colors)} />
            </When>
            <If condition={isDailyCost}>
              <Then>
                <When condition={!isTooltipDateDayAfterToday}>
                  {getTooltipItem(
                    getTooltipItemKey(areKeysByGranularity, granularity, key),
                    value,
                    currency
                  )}
                </When>
              </Then>
              <Else>
                {getTooltipItem(
                  correctKeyBasedOnGranularity(
                    areKeysByGranularity,
                    key,
                    granularity
                  ),
                  value,
                  currency
                )}
              </Else>
            </If>
          </FlexContainer>
        );
      })}
    </div>
  );
}

function getIconColor(key: string, colors: { [k: string]: string }) {
  if (key === CostAnalysisFields.COST_BEFORE_CORRECTION) {
    return colors[CostAnalysisFields.DAILY_COST] ?? "primary";
  }

  if (key === CostAnalysisFields.SAVINGS_PLANS) {
    return savingsPlanColor;
  }

  return colors[key] ?? "primary";
}

function mapEntries(entries: [string, string][]) {
  const daily = entries.find(([key]) => key === CostAnalysisFields.DAILY_COST);
  const dailyCost = Number(daily?.[1] ?? 0);

  const correction = entries.find(
    ([key]) => key === CostAnalysisFields.CORRECTION_COST
  );
  const correctionCost = Number(correction?.[1] ?? 0);

  if (correctionCost === 0 && daily) {
    return [[CostAnalysisFields.DAILY_COST, dailyCost.toFixed(2)]];
  }

  return [
    [CostAnalysisFields.DAILY_COST, (dailyCost + correctionCost).toFixed(2)],
    [CostAnalysisFields.COST_BEFORE_CORRECTION, dailyCost.toFixed(2)],
    [CostAnalysisFields.CORRECTION_COST, correctionCost.toFixed(2)],
  ];
}

function correctKeyBasedOnGranularity(
  areKeysByGranularity: boolean,
  key: string,
  granularity: Granularity
) {
  if (!areKeysByGranularity) {
    return "Cost before correction";
  }

  if (
    key === CostAnalysisFields.COST_BEFORE_CORRECTION &&
    granularity === Granularity.MONTHS
  ) {
    return "Monthly cost before correction";
  }

  return key;
}
