/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { ReactTable } from "~/components";
import { DetailData } from "~/components/Details/Detail";

const KUBERNETES_DETAILS = [
  "namespace",
  "containers",
  "name",
  "resourceVersion",
  "uid",
];

export const KUBERNETES_TABLE_CONTENT = {
  podStatus: "podStatus",
  podInfo: "podInfo",
  nodeCapacity: "nodeCapacity",
  nodeInfo: "nodeInfo",
};

const NODE_DETAILS_LABELS = [
  "architecture",
  "containerRuntimeVersion",
  "kernelVersion",
  "kubeProxyVersion",
  "kubeletVersion",
  "operatingSystem",
  "osImage",
];

type Props = {
  metadata: DetailData[];
  label: string;
  columns: unknown;
  tableContent: string;
};

export function DetailsList({ metadata, label, columns, tableContent }: Props) {
  const metadataFiltered = filterDetails(metadata, tableContent);
  return (
    <>
      {label !== "" && (
        <Text isTitle weight="medium">
          {label}
        </Text>
      )}
      <ReactTable
        columns={columns}
        data={metadataFiltered}
        sort={false}
        striped
      />
    </>
  );
}

function filterDetails(metadata: DetailData[], tableContent: string) {
  switch (tableContent) {
    case KUBERNETES_TABLE_CONTENT.podStatus:
      return filterPodStatus(metadata);
    case KUBERNETES_TABLE_CONTENT.podInfo:
      return metadata.filter((m) => KUBERNETES_DETAILS.includes(m.label));
    case KUBERNETES_TABLE_CONTENT.nodeCapacity:
      return filterCapacity(metadata);
    case KUBERNETES_TABLE_CONTENT.nodeInfo:
      return filterNodeInfo(metadata);
    default:
      return metadata;
  }
}

const filterPodStatus = (metadata: DetailData[]) => {
  const creationTimestamp = metadata.find((d) => d.label.includes("creation"));
  const podsStatuses = metadata.filter(({ label }) => label.includes("status"));
  const latestStatus = [...podsStatuses].reverse().shift();
  return [creationTimestamp, latestStatus];
};

const filterCapacity = (metadata: DetailData[]) => {
  return metadata
    .filter((m) => ["capacity", "allocatable"].includes(m.label))
    .filter(({ value }) => value);
};

const filterNodeInfo = (metadata: DetailData[]) => {
  const nodeDetails = metadata.filter(({ label }) => label === "nodeInfo");
  const details = nodeDetails.flatMap(({ value }) =>
    Object.values(value ?? "")
  );
  const detailsFiltered = details.filter(({ name }) =>
    NODE_DETAILS_LABELS.includes(name)
  );

  return detailsFiltered.map(({ name, value }) => ({ label: name, value }));
};
