/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Col,
  ModalConfirm,
  Row,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useTurbonomicIntegrationPluginQuery } from "~/generated/graphql";
import {
  BreadcrumbsBox,
  BrickLoader,
  ErrorMessage,
  LoaderWrap,
  UserErrorMessage,
} from "~/components";
import { useUuidValidator } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { useRemoveIntegrationPluginAction } from "../hooks/useRemoveIntegrationPluginAction";
import { TurbonomicIntegrationPluginsDetailsView } from "./TurbonomicIntegrationPluginsDetailsView";

export function IntegrationPluginsDetailsPage() {
  const { pluginId } = useParams<RouteParams>();
  useUuidValidator(pluginId);
  const [userError, setUserError] = React.useState<Error>();

  const {
    data,
    loading,
    error: loadError,
  } = useTurbonomicIntegrationPluginQuery({
    variables: { id: pluginId },
  });

  const turbonomicPlugin = data?.turbonomicIntegrationPlugin;
  const breadcrumbLabels = [
    { value: pluginId, label: turbonomicPlugin?.name ?? pluginId },
  ];

  const detailsComponent = turbonomicPlugin ? (
    <TurbonomicIntegrationPluginsDetailsView plugin={turbonomicPlugin} />
  ) : (
    <Box>
      <Text>Integration Plugin Not Found</Text>
    </Box>
  );

  const history = useHistory();
  const removeAction = useRemoveIntegrationPluginAction(() =>
    history.push(ROUTES.integrationPlugins.index)
  );

  return (
    <LoaderWrap
      loading={loading}
      Component={BrickLoader}
      viewBox="0 0 10 4"
      backgroundColor={theme.color.background.ui01}
    >
      <ErrorMessage error={loadError} />
      <UserErrorMessage
        error={userError}
        onClick={() => setUserError(undefined)}
      />
      <Col>
        <Row>
          <BreadcrumbsBox
            title={turbonomicPlugin?.name}
            labels={breadcrumbLabels}
          >
            <Button
              data-testid="delete-btn"
              icon="trash"
              severity="low"
              onClick={() => {
                turbonomicPlugin && removeAction.onClick(turbonomicPlugin);
              }}
            />
          </BreadcrumbsBox>
        </Row>
        <Row>{detailsComponent}</Row>
      </Col>

      <ModalConfirm {...removeAction.modalProps}>
        {removeAction.contentText}
      </ModalConfirm>
    </LoaderWrap>
  );
}

type RouteParams = {
  pluginId: string;
};
