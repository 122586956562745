/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EstatePeriod, Mode } from "~/components";
import { isNotEmpty } from "~/tools";
import { BillingPeriod } from "./types";

export function getNumberFilterValue(value: string): number | undefined {
  return isNotEmpty(value) ? Number(value) : undefined;
}

export function isDoubleFilterSelected(from?: string, to?: string) {
  return isFilterSelected(from) || isFilterSelected(to);
}

function isFilterSelected(value?: string) {
  return isNotEmpty(value ?? "");
}

export function mapEstatePeriodToBillingPeriod(period: EstatePeriod) {
  if (period === EstatePeriod.PREVIOUS_MONTH) {
    return BillingPeriod.Previous;
  }

  if (period === EstatePeriod.CURRENT_MONTH) {
    return BillingPeriod.Current;
  }
  return undefined;
}

export function isExcluded(mode: Mode) {
  return mode === Mode.EXCLUDE ? true : undefined;
}
