/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Button, FlexContainer, Label, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isNotNil } from "~/tools";
import {
  AzureFormFields,
  Provider,
  ProviderTab,
} from "~/views/cloudAccounts/types";
import { AdditionalCloudAccountSettings } from "../AdditionalCloudAccountSettings";
import { AzureFormData } from "../types";
import { useAddAccount } from "../useAddAccount";
import { addAzureSchema } from "../validators";
import { AzureAccountCredentialsInfo } from "./AzureAccountCredentialsInfo";
import { SubscriptionFields } from "./SubscriptionFields";

type Props = {
  closeSidebar: () => void;
};

export function AzureSidebarContent({ closeSidebar }: Props) {
  const [subscriptionIds, setSubscriptionIds] = useState([
    { id: uuidv4(), value: "" },
  ]);
  const formMethods = useForm<AzureFormData>({
    resolver: yupResolver(addAzureSchema),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { addAccount, loading } = useAddAccount(ProviderTab.AZURE);

  useEffect(() => {
    const newValue = subscriptionIds.map(({ value }) => value);
    setValue(AzureFormFields.SUBSCRIPTION_IDS, newValue);
  }, [subscriptionIds, setValue]);

  const onAddAccountSubmit = async (formData: AzureFormData) => {
    if (loading) {
      return;
    }

    try {
      const results = await Promise.all(
        formData.subscriptionIds.map(async (id) => {
          const input = {
            name: id,
            provider: Provider.Azure,
            providerId: id,
            ownerId: formData.accountOwner,
            displayName: formData.displayName,
            description: formData.description,
            contactIds: formData.contactPerson
              ? [formData.contactPerson]
              : undefined,
            creds: JSON.stringify({
              ownerId: formData.accountOwner,
              providerId: id,
              appId: formData.appId,
              password: formData.password,
              tenantId: formData.tenant,
            }),
          };

          return await addAccount({ variables: { input } });
        })
      );

      if (results.every((result) => isNotNil(result.data?.addAccountV3?.nid))) {
        showSuccess(SUCCESS_TEXT.accountAdded);
        closeSidebar();
      }
    } catch {
      showError(ERROR_TEXT.failedAddingAccount);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form id="add-azure-form" onSubmit={handleSubmit(onAddAccountSubmit)}>
        <SubscriptionFields
          subscriptionIds={subscriptionIds}
          setSubscriptionIds={setSubscriptionIds}
        />

        <FormGroup error={errors[AzureFormFields.ACCOUNT_OWNER] ?? []}>
          <Label
            required
            htmlFor={AzureFormFields.ACCOUNT_OWNER}
            name="Account Owner"
          />
          <ContactsSelector
            onChange={(selectedContact) => {
              setValue(AzureFormFields.ACCOUNT_OWNER, selectedContact);
            }}
          />
        </FormGroup>
        <AdditionalCloudAccountSettings />
        <AzureAccountCredentialsInfo />
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Button
            icon="checkmark"
            initialState={loading ? "loading" : ""}
            css={{ border: 0 }}
            type="submit"
            form="add-azure-form"
          >
            Apply
          </Button>
          <Button severity="low" type="button" onClick={closeSidebar}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
