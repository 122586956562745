/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import * as yup from "yup";
import {
  WorkflowExternalIntegrationType,
  WorkflowFrequency,
  WorkflowTriggerType,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isEmpty, isNotEmpty, isNotNil, isNil } from "~/tools";
import { WorkflowDataType } from "../constants";
import { SlackFormData, WorkflowFormData } from "../types";
import {
  SchedulerFields,
  ServiceNowFields,
  tomorrowsDate,
  SlackFields,
  WorkflowFields,
  EmailFields,
  SHORT_DESCRIPTION_LIMIT,
} from "./constants";

const emailRegExpValidation = /^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;

const validateEmail = (emails?: string) =>
  emails
    ?.split(";")
    .map((s) => s.trim())
    .every((mail: string) => emailRegExpValidation.test(mail)) ?? true;

const EmailFormSchema = yup.object().shape({
  [EmailFields.MAILTO]: emailMailToSchema(),
  [EmailFields.SUBJECT]: yup.string().required("Subject is required."),
  [EmailFields.MESSAGE]: yup.string().required("Message is required."),
  [EmailFields.SEND_TO_CONTACT_PERSONS]: yup.boolean(),
});

function getSchedulerFormSchema({
  validateSchedulerEndDate,
}: {
  validateSchedulerEndDate: boolean;
}) {
  return yup.object().shape({
    [SchedulerFields.START_DATE]: yup
      .string()
      .required("Start Date is required.")
      .when(SchedulerFields.REPEAT, {
        is: false,
        then: yup.string().test({
          name: "notInThePast",
          message: "Start Date cannot be in the past.",
          test: (date) =>
            date ? date >= dayjs().format(dateFormat.shortDate) : true,
        }),
      }),
    [SchedulerFields.EXECUTION_TIME]: yup
      .string()
      .required("Execution Time is required.")
      .when([SchedulerFields.START_DATE, SchedulerFields.REPEAT], {
        is: (startDate: string, repeat: boolean) =>
          !repeat && dayjs(startDate).isValid(),
        then: yup.string().test({
          name: "futureOnly",
          message: "Execution Time must be in the future.",
          test: (executionTime, { parent }) =>
            dayjs(
              `${parent.startDate} ${executionTime}`,
              dateFormat.shortDateWithTime
            ).isAfter(dayjs()),
        }),
      }),
    [SchedulerFields.WEEKDAYS]: yup
      .array()
      .when([SchedulerFields.FREQUENCY, SchedulerFields.REPEAT], {
        is: (frequency: WorkflowFrequency, repeat: boolean) =>
          frequency === WorkflowFrequency.Weekly && repeat,
        then: yup
          .array()
          .of(
            yup.object().shape({
              selected: yup.boolean(),
            })
          )
          .test({
            name: "atLeastOneSelected",
            message: "At least one weekday must be selected.",
            test: (array) => (array ?? []).some(({ selected }) => selected),
          }),
      }),
    [SchedulerFields.INTERVAL]: yup.number().when("repeat", {
      is: true,
      then: yup
        .number()
        .typeError("Frequency must be an integer.")
        .integer("Frequency must be an integer.")
        .min(1, "Frequency must be greater than 0."),
    }),
    [SchedulerFields.END_DATE]: yup.string().when("repeat", {
      is: (repeat: boolean) => repeat && validateSchedulerEndDate,
      then: yup
        .string()
        .test({
          name: "futureOnly",
          message: "End Date must be in the future",
          test: (date) => (date ? date >= tomorrowsDate : true),
        })
        .test({
          name: "laterThanStartDate",
          message: "End Date must be later than Start Date.",
          test: (date, { parent }) =>
            date ? date > parent[SchedulerFields.START_DATE] : true,
        }),
    }),
  });
}

const getServiceNowFormSchema = ({
  requireServiceNowPassword,
}: {
  requireServiceNowPassword: boolean;
}) =>
  yup.object().shape({
    [ServiceNowFields.URL]: yup
      .string()
      .url("URL must be valid.")
      .required("URL is required."),
    [ServiceNowFields.USERNAME]: yup.string().required("Username is required."),
    ...(requireServiceNowPassword
      ? {
          [ServiceNowFields.PASSWORD]: yup
            .string()
            .required("Password is required."),
        }
      : {}),
    [ServiceNowFields.SHORT_DESCRIPTION]: yup
      .string()
      .required("Short Description is required.")
      .max(
        SHORT_DESCRIPTION_LIMIT,
        `Short Description limit is ${SHORT_DESCRIPTION_LIMIT} characters.`
      ),
    [ServiceNowFields.ADDITIONAL_MESSAGE_FIELDS]: yup.array().of(
      yup.object().shape({
        key: yup
          .string()
          .required("Key is required.")
          .max(50, "Key limit is 50 characters."),
        value: yup
          .string()
          .required("Value is required.")
          .max(50, "Value limit is 50 characters."),
      })
    ),
    [ServiceNowFields.COSTS_OPTIONS]: getMultiValueCheckboxSchema(),
    [ServiceNowFields.KPI_OPTIONS]: getMultiValueCheckboxSchema(),
    [ServiceNowFields.SAVING_SUGGESTIONS_OPTIONS]:
      getMultiValueCheckboxSchema(),
  });

type SlackContextTuple = [
  {
    value: Record<string, boolean>;
  },
  {
    value: Record<string, SlackFormData>;
  },
  {
    value: Record<string, WorkflowDataType>;
  }
];

type FormValuesTuple = [
  {
    value: Record<string, SlackFormData["message"]>;
  },
  {
    value: Record<string, WorkflowDataType>;
  }
];

const FORM_VALUES_CONTEXT_INDEXES = {
  slackFormData: 0,
  selectDataType: 1,
} as const;

const SLACK_CONTEXT_INDEXES = {
  slackSelectDataOptions: 0,
  slackFormData: 1,
  selectDataType: 2,
} as const;

function getSlackFormSchema({
  requireSlackToken,
}: {
  requireSlackToken: boolean;
}) {
  return yup.object().shape({
    [SlackFields.CHANNEL]: yup.string().required("Channel is required."),
    [SlackFields.MESSAGE]: getSlackMessageSchema(),
    [SlackFields.SAVING_SUGGESTIONS_OPTIONS]: getMultiValueCheckboxSchema(),
    [SlackFields.KPI_OPTIONS]: getSlackKpiOptionsSchema(),
    [SlackFields.COSTS_OPTIONS]: getSlackCostsOtionsSchema(),
    ...(requireSlackToken
      ? {
          [SlackFields.TOKEN]: yup
            .string()
            .required("Authorization Token is required."),
        }
      : {}),
  });
}

function getSlackMessageSchema() {
  return yup
    .string()
    .nullable()
    .test("Slack message test", "Message is required.", (_, context) => {
      const contextGeneric = context as unknown as {
        from: FormValuesTuple;
      };
      const { selectDataComponent } =
        contextGeneric.from[FORM_VALUES_CONTEXT_INDEXES.selectDataType].value;

      if (selectDataComponent === WorkflowDataType.UNALLOCATED_RESOURCES) {
        const slackMessage =
          contextGeneric.from[FORM_VALUES_CONTEXT_INDEXES.slackFormData].value
            .message;
        return isNil(slackMessage) || isEmpty(slackMessage ?? "");
      }
      return true;
    });
}

function getMultiValueCheckboxSchema() {
  return yup
    .object()
    .nullable()
    .test(
      "atLeastOneSelected",
      "At least one option needs to be selected.",
      (value) => (isNotNil(value) ? Object.values(value).some(Boolean) : true)
    );
}

function getSlackKpiOptionsSchema() {
  return yup
    .object()
    .nullable()
    .test(
      "Slack kpi options test",
      "At least one option is required.",
      (_, context) => {
        const contextGeneric = context as unknown as {
          from: SlackContextTuple;
        };

        if (
          contextGeneric.from[SLACK_CONTEXT_INDEXES.slackFormData].value
            .kpiOptions === null
        ) {
          return true;
        }

        const { selectDataComponent } =
          contextGeneric.from[SLACK_CONTEXT_INDEXES.selectDataType].value;

        if (selectDataComponent !== "KPI") {
          return true;
        }

        const { costAllocation, nonProdHours, overallWaste } =
          contextGeneric.from[SLACK_CONTEXT_INDEXES.slackSelectDataOptions]
            .value;

        return [costAllocation, nonProdHours, overallWaste].some(Boolean);
      }
    );
}

function getSlackCostsOtionsSchema() {
  return yup
    .object()
    .nullable()
    .test(
      "Slack costs options test",
      "At least one option is required.",
      (_, context) => {
        const contextGeneric = context as unknown as {
          from: SlackContextTuple;
        };

        if (
          contextGeneric.from[SLACK_CONTEXT_INDEXES.slackFormData].value
            .costsOptions === null
        ) {
          return true;
        }

        const { selectDataComponent } =
          contextGeneric.from[SLACK_CONTEXT_INDEXES.selectDataType].value;

        if (selectDataComponent !== "COSTS") {
          return true;
        }

        const {
          totalMonthlyCost,
          totalMonthlyForecast,
          costsByCategories,
          costsByProviders,
        } =
          contextGeneric.from[SLACK_CONTEXT_INDEXES.slackSelectDataOptions]
            .value;

        return [
          totalMonthlyCost,
          totalMonthlyForecast,
          costsByCategories,
          costsByProviders,
        ].some(Boolean);
      }
    );
}

export const getWorkflowFormSchema = ({
  requireSlackToken,
  requireServiceNowPassword,
  validateSchedulerEndDate,
}: {
  requireSlackToken: boolean;
  requireServiceNowPassword: boolean;
  validateSchedulerEndDate: boolean;
}) =>
  yup.object().shape({
    [WorkflowFields.NAME]: yup
      .string()
      .required("Workflow Name is required.")
      .max(50, "Workflow Name limit is 50 characters."),
    [WorkflowFields.DESCRIPTION]: yup
      .string()
      .max(255, "Workflow Description limit is 255 characters."),
    [WorkflowFields.CONTACT_PERSON_IDS]: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
        })
      )
      .test({
        name: "required",
        message: "At least one Contact Person is required.",
        test: (array) => (array ?? []).some(({ value }) => value !== ""),
      })
      .test({
        name: "required",
        message: "Empty Contact Person fields must be filled or removed.",
        test: (array) =>
          (array ?? []).filter(({ value }) => value === "").length === 0,
      })
      .test({
        name: "unique",
        message: "The same person cannot be selected more than once.",
        test: (array) =>
          (array ?? []).length ===
          new Set(array?.map(({ value }) => value)).size,
      }),
    [WorkflowFields.SCHEDULER]: yup.object().when("triggerComponents", {
      is: (triggerComponents: WorkflowTriggerType[]) =>
        triggerComponents.includes(WorkflowTriggerType.Scheduler),
      then: getSchedulerFormSchema({ validateSchedulerEndDate }),
    }),
    [WorkflowFields.BUDGETS_OPTIONS]: budgetsOptionsSchema(),
    [WorkflowFields.SAVINGS_SUGGESTIONS]: yup
      .object()
      .when("selectDataComponent", {
        is: WorkflowDataType.SAVING_SUGGESTIONS,
        then: appsAndEnvsScheme(),
      }),
    [WorkflowFields.COSTS_OPTIONS]: yup.object().when("selectDataComponent", {
      is: WorkflowDataType.COSTS,
      then: appsAndEnvsScheme(),
    }),
    [WorkflowFields.ANOMALY_COSTS_OPTIONS]: yup
      .object()
      .when("selectDataComponent", {
        is: WorkflowDataType.ANOMALY_COSTS,
        then: appsAndEnvsScheme(),
      }),
    [WorkflowFields.KPI_OPTIONS]: yup.object().when("selectDataComponent", {
      is: WorkflowDataType.KPI,
      then: yup.object().shape({
        businessContextId: yup
          .string()
          .required("You must select a Business Context."),
      }),
    }),
    [WorkflowFields.EMAIL]: yup.object().when("sendDataComponents", {
      is: (sendDataComponents: WorkflowExternalIntegrationType[]) =>
        sendDataComponents.includes(WorkflowExternalIntegrationType.Email),
      then: EmailFormSchema,
    }),
    [WorkflowFields.SERVICE_NOW]: yup.object().when("sendDataComponents", {
      is: (sendDataComponents: WorkflowExternalIntegrationType[]) =>
        sendDataComponents.includes(WorkflowExternalIntegrationType.ServiceNow),
      then: getServiceNowFormSchema({
        requireServiceNowPassword,
      }),
    }),
    [WorkflowFields.SLACK]: yup.object().when("sendDataComponents", {
      is: (sendDataComponents: WorkflowExternalIntegrationType[]) =>
        sendDataComponents.includes(WorkflowExternalIntegrationType.Slack),
      then: getSlackFormSchema({
        requireSlackToken,
      }),
    }),
    [WorkflowFields.TRIGGER_COMPONENTS]: yup.array().of(yup.string()).min(1),
    [WorkflowFields.SELECT_DATA_COMPONENT]: yup.string().required(),
    [WorkflowFields.SEND_DATA_COMPONENTS]: yup.array().of(yup.string()).min(1),
  });

function appsAndEnvsScheme() {
  return yup.object().test({
    name: "atLeastOneItem",
    test: (costsOptions) => {
      return (
        isNotEmpty(costsOptions.applications ?? []) ||
        isNotEmpty(costsOptions.environments ?? []) ||
        isNotEmpty(costsOptions.orgUnitIds ?? [])
      );
    },
  });
}

function budgetsOptionsSchema() {
  return yup.object().when("selectDataComponent", {
    is: WorkflowDataType.BUDGETS,
    then: yup.object().shape({
      costGtBudget: yup
        .boolean()
        .test(
          "CostGtBudget & ForecastGtBudget test",
          "At least one option is required.",
          (_, context) => {
            const { forecastGtBudget, costGtBudget } =
              context.parent as WorkflowFormData["budgetsOptions"];

            return forecastGtBudget || costGtBudget;
          }
        ),
      orgUnitIds: yup
        .array()
        .of(yup.string())
        .test({
          name: "atLeastOneItem",
          test: (orgUnitsIds, { parent }) => {
            const { applications, environments } =
              parent as WorkflowFormData["budgetsOptions"];

            return (
              isNotEmpty(orgUnitsIds ?? []) ||
              isNotEmpty(applications) ||
              isNotEmpty(environments)
            );
          },
        }),
    }),
  });
}

function emailMailToSchema() {
  const toggleFieldNames = [
    EmailFields.SEND_TO_CONTACT_PERSONS,
    EmailFields.SEND_TO_ADDITIONAL_USERS,
  ];

  return yup
    .string()
    .when(toggleFieldNames, {
      is: (sendToContactPersons: boolean, sendToAdditionalUsers: boolean) => {
        return sendToContactPersons && sendToAdditionalUsers;
      },
      then: yup
        .string()
        .required("At least one email address is required.")
        .test("emails", "Email must be valid.", validateEmail),
    })
    .when(toggleFieldNames, {
      is: (sendToContactPersons: boolean, sendToAdditionalUsers: boolean) => {
        return sendToContactPersons && !sendToAdditionalUsers;
      },
      then: yup.string().test({
        name: "emails",
        message: "Email must be valid.",
        test: (value?: string) => (value ? validateEmail(value) : true),
      }),
    })
    .when(toggleFieldNames, {
      is: (sendToContactPersons: boolean, sendToAdditionalUsers: boolean) => {
        return !sendToContactPersons && sendToAdditionalUsers;
      },
      then: yup
        .string()
        .required("At least one email address is required.")
        .test("emails", "Email must be valid.", validateEmail),
    })
    .test(
      "when there are no toggle fields",
      ({ originalValue }) =>
        isNotNil(originalValue)
          ? "Email must be valid."
          : "At least one email address is required.",
      (value, context) => {
        const contextGeneric = context as unknown as {
          from: FormValuesTuple;
        };
        const { selectDataComponent } =
          contextGeneric.from[FORM_VALUES_CONTEXT_INDEXES.selectDataType].value;

        if (
          [
            WorkflowDataType.UNALLOCATED_RESOURCES,
            WorkflowDataType.CLOUD_CREDENTIALS,
          ].includes(selectDataComponent)
        ) {
          return isNotNil(value) ? validateEmail(value) : false;
        }

        return true;
      }
    );
}
