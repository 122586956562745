/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useTabsWithUrl, useEstateFetch } from "~/hooks";
import { isCloudResource } from "~/tools";
import {
  disabledFilterFields,
  DisabledFilters,
} from "~/views/estate/components/EstateFilters";
import { Ec2RelatedResourcesTab, EstateTabsData } from "../components";

type Props = {
  data: EstateTabsData;
};

export function Ec2SecurityGroupTabs({ data }: Props) {
  const { activeTab, setActiveTab } = useTabsWithUrl();

  const resourceName =
    data.estateRecord && isCloudResource(data.estateRecord)
      ? data.estateRecord.name
      : "";

  const { result, ...filters } = useEstateFetch({
    defaultFilters: {
      typeSubtype: ["ec2/instance"],
      securityGroup: [resourceName],
    },
    skipFields: disabledFilterFields.get(DisabledFilters.APPS),
    activeTab,
  });

  const estateRecordsData = useMemo(() => {
    return {
      ...result,
      data: {
        estateV2: result.data,
      },
    };
  }, [result]);

  return (
    <Ec2RelatedResourcesTab
      data={data}
      dataEstate={estateRecordsData}
      estateFilters={filters}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}
