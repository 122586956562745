/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Switch } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { CloudAccountsQueryState, ProviderTab } from "../../types";
import { isKubernetesOrOpenshiftTab } from "../../utils";
import { AwsSidebarContent } from "./AwsSidebarContent/AwsSidebarContent";
import { AzureSidebarContent } from "./AzureSidebarContent/AzureSidebarContent";
import { GcpSidebarContent } from "./GcpSidebarContent";
import { HybridSidebarContent } from "./HybridSidebarContent";
import { IbmCloudSidebarContent } from "./IbmCloudSidebarContent";
import { VmWareSidebarContent } from "./VmWareSidebarContent";

type Props = {
  isOpen: boolean;
  close: () => void;
};

export function AddNewAccountSidebar({ isOpen, close }: Props) {
  const {
    state: { provider },
  } = useQueryState<CloudAccountsQueryState>();

  return (
    <Sidebar
      title={getSidebarTitle(provider)}
      width="40%"
      isOpen={isOpen}
      onClick={close}
    >
      <Switch>
        <Case condition={provider === ProviderTab.AWS}>
          <AwsSidebarContent closeSidebar={close} />
        </Case>
        <Case condition={provider === ProviderTab.AZURE}>
          <AzureSidebarContent closeSidebar={close} />
        </Case>
        <Case condition={provider === ProviderTab.GCP}>
          <GcpSidebarContent closeSidebar={close} />
        </Case>
        <Case condition={isKubernetesOrOpenshiftTab(provider)}>
          <HybridSidebarContent provider={provider} closeSidebar={close} />
        </Case>
        <Case condition={provider === ProviderTab.IBMCLOUD}>
          <IbmCloudSidebarContent closeSidebar={close} />
        </Case>
        <Case condition={provider === ProviderTab.VMWARE}>
          <VmWareSidebarContent closeSidebar={close} />
        </Case>
      </Switch>
    </Sidebar>
  );
}

function getSidebarTitle(provider: ProviderTab) {
  switch (provider) {
    case ProviderTab.KUBERNETES:
      return "Onboard Kubernetes Clusters";
    case ProviderTab.OPENSHIFT:
      return "Onboard OpenShift Clusters";
    case ProviderTab.VMWARE:
      return "Onboard VMWare";
    default:
      return "Add Cloud Accounts";
  }
}
