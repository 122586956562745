/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import { useHistory, generatePath } from "react-router-dom";
import { ExtendedTooltip, Sidebar, SVGIcon, theme } from "@nordcloud/gnui";
import { useMutationHandler } from "~/hooks";
import { useAddBusinessContext } from "~/hooks/graphql";
import { ROUTES } from "~/routing/routes";
import {
  BusinessContextList,
  BusinessContextsForm,
  FormValues,
} from "~/views/businessContexts";
import { MenuLink } from "../components";
import { useSidebarVisibility } from "../sidebarVisibility";
import { BusinessContextItem, useGetBusinessContext } from "./hooks";
import {
  BusinessContextBox,
  BusinessContextButton,
  ShowAll,
  SubmenuItem,
} from "./styles";
import { SubmenuTitle } from "./SubmenuTitle";

type Props = {
  businessContexts: BusinessContextItem[];
};

export function BusinessContextSubmenu({ businessContexts }: Props) {
  const [isAddNewContextSidebarOpen, setIsAddNewContextSidebarOpen] =
    useState(false);
  const [isShowAllSidebarOpen, setIsShowAllSidebarOpen] = useState(false);

  const history = useHistory();

  const { primary, nonPrimary, showMoreButton } =
    useGetBusinessContext(businessContexts);

  const { setSidebarOpened } = useSidebarVisibility();

  const [addBusinessContext] = useAddBusinessContext();

  const { runMutation } = useMutationHandler();

  const onSubmit = async (values: FormValues) => {
    handleAction(setIsAddNewContextSidebarOpen, false);

    const data = await runMutation({
      identifier: `The ${values.name}`,
      mutation: () => addBusinessContext({ input: values }),
      successAction: "added",
    });

    if (data != null) {
      history.push(
        generatePath(ROUTES.businessContexts.structure, {
          structureId: data.id,
        })
      );
    }
  };

  const handleAction = (
    sidebarHandler: (sidebarState: boolean) => void,
    state: boolean
  ) => {
    sidebarHandler(state);
    setSidebarOpened(state);
  };

  const showPrimaryBusinessContext = primary != null;

  return (
    <>
      <SubmenuTitle title="Business Context" />
      <ul>
        {showPrimaryBusinessContext ? (
          <li>
            <MenuLink to={getUrl(primary.id)}>
              <SubmenuItem>
                <BusinessContextBox color={primary.color} />
                {primary.name}
                <ExtendedTooltip
                  caption="Primary Business Context"
                  placement="top"
                >
                  <SVGIcon
                    name="starFilled"
                    size="sm"
                    css={{ marginLeft: theme.spacing.spacing02 }}
                  />
                </ExtendedTooltip>
              </SubmenuItem>
            </MenuLink>
          </li>
        ) : null}
        {nonPrimary.map((context) => (
          <li key={context.id}>
            <MenuLink to={getUrl(context.id)}>
              <SubmenuItem>
                <BusinessContextBox color={context.color} />
                {context.name}
              </SubmenuItem>
            </MenuLink>
          </li>
        ))}
      </ul>
      <When condition={showMoreButton}>
        <ShowAll
          as="span"
          onClick={() => handleAction(setIsShowAllSidebarOpen, true)}
        >
          Show all
        </ShowAll>
      </When>
      <BusinessContextButton
        size="md"
        onClick={() => handleAction(setIsAddNewContextSidebarOpen, true)}
      >
        Add new Business Context
      </BusinessContextButton>
      <Sidebar
        title="Add New Business Context"
        isOpen={isAddNewContextSidebarOpen}
        onClick={() => handleAction(setIsAddNewContextSidebarOpen, false)}
      >
        <BusinessContextsForm
          hasColor
          isEditMode={false}
          type="Business Contexts"
          onSubmit={onSubmit}
        />
      </Sidebar>
      <Sidebar
        title="List of all Business Context"
        isOpen={isShowAllSidebarOpen}
        side="onLeft"
        onClick={() => handleAction(setIsShowAllSidebarOpen, false)}
      >
        <BusinessContextList
          data={businessContexts}
          onItemClicked={() => handleAction(setIsShowAllSidebarOpen, false)}
        />
      </Sidebar>
    </>
  );
}

function getUrl(id: string) {
  return `${ROUTES.businessContexts.index}/${id}`;
}
