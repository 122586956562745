/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useMemo } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { Button, FlexContainer, Tag, Text, theme } from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { CloudAccountStatus, isNotEmpty, isNotNil } from "~/tools";
import { CloudAccountsQueryState, Provider } from "../types";

const activeFilterTagProps = {
  [CloudAccountStatus.NoAccess]: {
    id: CloudAccountStatus.NoAccess,
    label: "No Access",
    color: "danger" as const,
  },
  [CloudAccountStatus.NoData]: {
    id: CloudAccountStatus.NoData,
    label: "No Data",
    color: undefined,
  },
  [CloudAccountStatus.BillingOnly]: {
    id: CloudAccountStatus.BillingOnly,
    label: "Billing data only",
    color: "warning" as const,
  },
  [CloudAccountStatus.ApiOnly]: {
    id: CloudAccountStatus.ApiOnly,
    label: "Cloud Estate API",
    color: "warning" as const,
  },
  [CloudAccountStatus.FullAccess]: {
    id: CloudAccountStatus.FullAccess,
    label: "Full access",
    color: "success" as const,
  },
  [Provider.Aws]: {
    id: Provider.Aws,
    label: Provider.Aws,
    color: undefined,
  },
  [Provider.Azure]: {
    id: Provider.Azure,
    label: Provider.Azure,
    color: undefined,
  },
  [Provider.Gcp]: {
    id: Provider.Gcp,
    label: Provider.Gcp,
    color: undefined,
  },
};

export function ActiveFilters() {
  const { state, updateQueryState } = useQueryState<CloudAccountsQueryState>();
  const {
    status = [],
    providerFilter = [],
    excludeStatus,
    excludeProvider,
  } = state;

  const handleRemoveFilter = useCallback(
    (valueToRemove: string) => {
      updateQueryState({
        ...state,
        status: status.filter((value) => value !== valueToRemove),
        providerFilter: providerFilter.filter(
          (value) => value !== valueToRemove
        ),
      });
    },
    [providerFilter, state, status, updateQueryState]
  );

  const handleClearFilters = () => {
    updateQueryState({
      ...state,
      excludeStatus: undefined,
      excludeProvider: undefined,
      status: [],
      providerFilter: [],
      page: 0,
    });
  };

  const [statusTags, providerTags] = useMemo(
    () =>
      [status, providerFilter].map((filters) =>
        filters
          .map(getTagProps)
          .filter(isNotNil)
          .map(({ color, id, label }) => (
            <Tag
              key={id}
              showClose
              text={label}
              color={color}
              css={{ margin: 0 }}
              onClick={() => {
                handleRemoveFilter(id);
              }}
            />
          ))
      ),
    [status, providerFilter, handleRemoveFilter]
  );

  return (
    <When condition={isNotEmpty(statusTags) || isNotEmpty(providerTags)}>
      <FiltersSectionWrapper>
        <FlexContainer direction="column" alignItems="start">
          <div>
            <Text size="sm" color={theme.color.text.text02}>
              Applied filters:
            </Text>
          </div>
          <When condition={isNotEmpty(statusTags)}>
            <FlexContainer
              alignItems="center"
              columnGap={theme.spacing.spacing02}
              mt={theme.spacing.spacing02}
            >
              <div>
                <Text m="0">{excludeStatus ? "Excluded" : "Included"}:</Text>
              </div>
              <FlexContainer columnGap={theme.spacing.spacing02}>
                {statusTags}
              </FlexContainer>
            </FlexContainer>
          </When>
          <When condition={isNotEmpty(providerTags)}>
            <FlexContainer
              alignItems="center"
              columnGap={theme.spacing.spacing02}
              mt={theme.spacing.spacing02}
            >
              <div>
                <Text m="0">{excludeProvider ? "Excluded" : "Included"}:</Text>
              </div>
              <FlexContainer columnGap={theme.spacing.spacing02}>
                {providerTags}
              </FlexContainer>
            </FlexContainer>
          </When>
        </FlexContainer>
        <FlexContainer css={{ alignSelf: "end" }}>
          <Button
            icon="filterClear"
            severity="low"
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        </FlexContainer>
      </FiltersSectionWrapper>
    </When>
  );
}

const FiltersSectionWrapper = styled(FlexContainer)`
  padding-top: ${theme.spacing.spacing02};
  border-top: 1px solid ${theme.color.border.border01};
  margin-top: ${theme.spacing.spacing03};
  justify-content: space-between;
  align-items: start;
`;

const getTagProps = (filter: string) => {
  const tagProps = Object.entries(activeFilterTagProps).find(
    ([key]) => key === filter
  );
  return tagProps ? tagProps[1] : null;
};
