/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Currency } from "~/tools";
import { ChartDates, ChartType, Granularity } from "../types";
import { AccumulatedCostTooltip } from "./AccumulatedCostTooltip";
import { StackTooltip } from "./StackTooltip";

type Props = {
  data: {
    date: string;
  }[];
  index: number;
  granularity: Granularity;
  currency: Currency;
  colors: { [k: string]: string };
  dateRangeDates?: ChartDates[];
  chartType?: ChartType;
  areKeysByGranularity?: boolean;
};

export function StackBarTooltip({
  data,
  index,
  granularity,
  currency,
  colors,
  dateRangeDates,
  chartType,
  areKeysByGranularity,
}: Props) {
  const isCostAnalysisAccumulatedChart =
    chartType === ChartType.ACCUMULATED_COST;

  return (
    <If condition={isCostAnalysisAccumulatedChart}>
      <Then>
        <AccumulatedCostTooltip
          data={data}
          index={index}
          granularity={granularity}
          currency={currency}
          colors={colors}
          dateRangeDates={dateRangeDates}
          areKeysByGranularity={areKeysByGranularity}
        />
      </Then>
      <Else>
        <StackTooltip
          data={data}
          index={index}
          granularity={granularity}
          currency={currency}
          colors={colors}
          dateRangeDates={dateRangeDates}
        />
      </Else>
    </If>
  );
}
