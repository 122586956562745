/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Select, Text, theme } from "@nordcloud/gnui";
import { EstateGroupBy, estateGroupByOptions } from "./types";

type Props = {
  activeChart: EstateGroupBy;
  handleGroupBySelect: (value: EstateGroupBy) => void;
  disabled?: boolean;
};

export function GroupingSelector({
  activeChart,
  handleGroupBySelect,
  disabled = false,
}: Props) {
  return (
    <FlexContainer marginRight={theme.spacing.spacing04}>
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Group By
      </Text>
      <Select
        name="GroupBy"
        options={estateGroupByOptions}
        isDisabled={disabled}
        value={{
          label: activeChart,
          value: activeChart,
        }}
        styles={{
          control: (base) => ({
            ...base,
            minWidth: "13rem",
          }),
        }}
        onChange={(value) =>
          handleGroupBySelect(value?.value ?? EstateGroupBy.NONE)
        }
      />
    </FlexContainer>
  );
}
