/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  FlexContainer,
  Input,
  Label,
  Message,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import {
  CloudProviderType,
  HybridFormFields,
  Provider,
  ProviderTab,
} from "../../types";
import { HybridFormData } from "./types";
import { useAddAccount } from "./useAddAccount";
import { addHybridSchema } from "./validators";

type Props = {
  provider: ProviderTab;
  clusterName?: string;
  ownerId?: string;
  closeSidebar: () => void;
};

export function HybridSidebarContent({
  provider,
  closeSidebar,
  clusterName,
  ownerId,
}: Props) {
  const formMethods = useForm<HybridFormData>({
    resolver: yupResolver(addHybridSchema),
    defaultValues: { clusterName, accountOwner: ownerId },
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { addAccount, loading } = useAddAccount(provider);

  const onAddAccountSubmit = async (formData: HybridFormData) => {
    if (loading) {
      return;
    }

    try {
      await addAccount({
        variables: {
          input: {
            name: formData.clusterName,
            ownerId: formData.accountOwner,
            provider: Provider.Hybrid,
            providerType:
              provider === ProviderTab.KUBERNETES
                ? CloudProviderType.Kubernetes
                : CloudProviderType.Openshift,
          },
        },
      });

      showSuccess(SUCCESS_TEXT.accountAdded);
      closeSidebar();
    } catch {
      showError(ERROR_TEXT.failedAddingAccount);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id={`add-${provider}-form`}
        onSubmit={handleSubmit(onAddAccountSubmit)}
      >
        <Message status="notification" image="info">
          <FlexContainer direction="column" alignItems="start">
            <Text
              weight="medium"
              color={theme.color.text.info}
              mb={theme.spacing.spacing00}
            >
              On-boarding process
            </Text>
            <Text
              mt={theme.spacing.spacing02}
              mb={theme.spacing.spacing00}
              color={theme.color.text.text02}
            >
              You can onboard to MCA your Kubernetes clusters and map your
              resources to the proper applications and environments. Here we
              prepared easy two-step onboarding process where you can create
              your cluster to be visible in MCA as a first step, and after that
              you deploy simple .yaml file inside your cluster to feed MCA with
              the data.
            </Text>
          </FlexContainer>
        </Message>
        <Spacer height={theme.spacing.spacing06} />
        <FormGroup error={errors[HybridFormFields.CLUSTER_NAME]}>
          <Label
            required
            htmlFor={HybridFormFields.CLUSTER_NAME}
            name="Cluster Name"
          />
          <Input
            placeholder="e.g., cluster name"
            {...register(HybridFormFields.CLUSTER_NAME)}
          />
        </FormGroup>
        <FormGroup error={errors[HybridFormFields.ACCOUNT_OWNER] ?? []}>
          <Label
            required
            htmlFor={HybridFormFields.ACCOUNT_OWNER}
            name="Cluster Owner"
          />
          <ContactsSelector
            onChange={(selectedContact) =>
              setValue(HybridFormFields.ACCOUNT_OWNER, selectedContact)
            }
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing03} />

        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Button
            icon="checkmark"
            initialState={loading ? "loading" : ""}
            css={{ border: 0 }}
            type="submit"
            form={`add-${provider}-form`}
          >
            Apply
          </Button>
          <Button severity="low" type="button" onClick={closeSidebar}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
