/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { ChartGroupTimePoint } from "~/generated/graphql";
import {
  ChartType,
  CostAnalysisFields,
  DateRange,
  EstatePeriod,
} from "~/components";
import { mapSwitcherGroupLabel } from "~/components/Charts/CostAnalysis/helpers";
import { dateFormat } from "~/constants";
import { isNotNil } from "~/tools";
import { ChartData, ExtraChartDetails } from "./types";

export function getStartDate(
  period: EstatePeriod,
  range: DateRange | undefined
) {
  const currentMonthStart = dayjs()
    .startOf("month")
    .format(dateFormat.shortDate);

  if (period === EstatePeriod.RANGE) {
    return range?.from ?? currentMonthStart;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs().subtract(1, "month").startOf("month").format(dateFormat.shortDate)
    : currentMonthStart;
}

export function getEndDate(period: EstatePeriod, range: DateRange | undefined) {
  const currentMonthEnd = dayjs().endOf("month").format(dateFormat.shortDate);

  if (period === EstatePeriod.RANGE) {
    return range?.to ?? currentMonthEnd;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs().subtract(1, "month").endOf("month").format(dateFormat.shortDate)
    : currentMonthEnd;
}

export function getChartGroups(
  timePoints: ChartGroupTimePoint[],
  chartType: ChartType
) {
  return timePoints
    .flatMap((timePoint) =>
      timePoint.groups?.flatMap((group) =>
        group?.name === "other"
          ? mapSwitcherGroupLabel(chartType)
          : group?.name ?? ""
      )
    )
    .filter(isNotNil);
}

export function mapChartDataCorrections(data: ChartData) {
  return (data.timePoints ?? []).filter(isNotNil).map((timePoint) => ({
    ...timePoint,
    groups: timePoint?.groups?.map((group) => {
      if (group?.id === "correction") {
        return {
          ...group,
          name: CostAnalysisFields.CORRECTION_COST,
        };
      }
      return group;
    }),
  }));
}

// 'other' group should be the last
export function reorderGroups(groups: string[]) {
  const otherGroup = groups.find((group) => group.includes("other"));
  const filteredGroups = groups.filter((group) => !group.includes("other"));

  if (isNotNil(otherGroup)) {
    return [...filteredGroups, otherGroup];
  }

  return groups;
}

export function getExtraCostDetails(extraDetails: ExtraChartDetails) {
  const totalTop =
    extraDetails.find((detail) => detail?.name === "TotalTop")?.value ?? "0";
  const totalOther =
    extraDetails.find((detail) => detail?.name === "TotalOther")?.value ?? "0";

  return {
    totalTop,
    totalOther,
  };
}

export function getAverageCost(
  totalCost: string,
  startDate: string,
  endDate: string
) {
  const totalMonths =
    dayjs(endDate)
      .endOf("month")
      .diff(dayjs(startDate).startOf("month"), "month") + 1;

  return (Number(totalCost) / totalMonths).toFixed(2);
}
