/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Description,
  FlexContainer,
  InputSearch,
  theme,
} from "@nordcloud/gnui";
import { DetailData } from "~/components/Details/Detail";
import { isNotEmpty, isObject } from "~/tools";
import { DownloadJsonFileButton } from "./DownloadJsonFileButton";
import { SearchableJson } from "./SearchableJson";

type AdvancedDetailsProps = {
  data?: DetailData;
};

type RouteParams = {
  nordcloudId: string;
};

export function AdvancedDetails({ data }: AdvancedDetailsProps) {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [results, setResults] = React.useState(0);

  const { nordcloudId } = useParams<RouteParams>();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchKey(searchInput);
      setIsCollapsed(false);
    }
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    setSearchKey("");
  };

  return (
    <>
      <Box boxStyle="lightGrey" mb={theme.spacing.spacing03}>
        <FlexContainer
          gap={theme.spacing.spacing02}
          justifyContent="space-between"
          alignItems="center"
        >
          <InputSearch
            value={searchInput}
            placeholder="Search"
            name="search"
            onKeyPress={handleKeyPress}
            onChange={onSearchInputChange}
            onSearch={() => setSearchKey(searchInput)}
          />
          <Button
            severity="low"
            icon={isCollapsed ? "expand" : "collapse"}
            data-testid="collapse-button"
            onClick={() => setIsCollapsed((prevState) => !prevState)}
          >
            {`${isCollapsed ? "Expand" : "Collapse"} All`}
          </Button>
          <DownloadJsonFileButton
            data={isObject(data?.value) ? data?.value : undefined}
            buttonName="Download JSON"
            fileName={nordcloudId}
          />
        </FlexContainer>
        {results === 0 && isNotEmpty(searchKey) && (
          <Description css={{ margin: 0 }}>No results found</Description>
        )}
      </Box>
      <SearchableJson
        data={data}
        isCollapsed={isCollapsed}
        search={searchKey}
        setResults={setResults}
      />
    </>
  );
}
