/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect } from "react";
import { User } from "@auth0/auth0-react";
import dayjs from "dayjs";
import {
  useUpdateUserLastLoginDateMutation,
  useUserPermissionsQuery,
} from "~/generated/graphql";
import { NODE_ENV } from "~/config";
import { ERROR_TEXT, dateFormat } from "~/constants";
import { isNotNil } from "~/tools";
import { useCustomers } from "./customers";
import { useDatadogRUM, datadogLogs } from "./datadog";
import { showError } from "./toast";
import { useUserflow } from "./userflow";

type SelectedCustomer = ReturnType<typeof useCustomers>["selectedCustomer"];

type UserData =
  | (User & {
      selectedCustomer?: SelectedCustomer;
    })
  | undefined;

export function useSetupUserTracking(userData: UserData) {
  useSetupDatadogUser(userData);
  useSetupUserflow(userData);
}

function useSetupDatadogUser(userData: UserData) {
  const ddRum = useDatadogRUM();

  useEffect(() => {
    if (isNotNil(ddRum) && isNotNil(userData)) {
      ddRum.setUser({
        id: userData.email,
        email: userData.email,
        customerId: userData.selectedCustomer?.id,
        customer: userData.selectedCustomer?.name,
      });
      datadogLogs.setUser({
        id: userData.email,
        email: userData.email,
        customerId: userData.selectedCustomer?.id,
        customer: userData.selectedCustomer?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `userData?.email` and `userData.selectedCustomer.id` assert uniqueness
  }, [ddRum, userData?.email, userData?.selectedCustomer?.id]);
}

function useSetupUserflow(userData: UserData) {
  const { initialize } = useUserflow();

  useEffect(() => {
    if (NODE_ENV === "production" && isNotNil(userData)) {
      initialize(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `initialize` can be ignored, `userData.email` asserts uniqueness
  }, [userData?.email]);
}

export function useUpdateUserLastLoginDate(userData: User) {
  const { email } = userData;
  const userEmail = email ?? "";

  const { data, loading } = useUserPermissionsQuery({
    variables: {
      email: userEmail,
    },
    skip: !email,
  });

  const userPreviousLoginDate = dayjs(
    data?.userPermissions?.lastLoginDate
  ).format(dateFormat.shortDate);

  const userCurrentLogin = dayjs().format(dateFormat.shortDate);
  const hasUpdatedLoginDate =
    !loading && userPreviousLoginDate !== userCurrentLogin;

  const [updateUserLastLogin] = useUpdateUserLastLoginDateMutation();

  const updateLastLogin = useCallback(() => {
    updateUserLastLogin({
      variables: {
        email: userEmail,
      },
    }).catch(() => {
      showError(ERROR_TEXT.default);
    });
  }, [updateUserLastLogin, userEmail]);

  useEffect(() => {
    if (hasUpdatedLoginDate) {
      updateLastLogin();
    }
  }, [updateLastLogin, hasUpdatedLoginDate]);
}
