/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useRef } from "react";
import { ApolloError } from "@apollo/client";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import { useClickAway } from "react-use";
import styled from "styled-components";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { ErrorMessage, LoaderWrap } from "~/components";
import { ROUTES } from "~/routing/routes";
import { extendedEncodeURI, isNotEmpty } from "~/tools";
import { ResultItem } from "./ResultItem";
import { SearchResult } from "./types";

type Props = {
  phrase: string;
  results: SearchResult[];
  loading: boolean;
  error: ApolloError | undefined;
  onSetShowResults: (state: boolean) => void;
};

export function SearchBoxResults({
  phrase,
  results,
  loading,
  error,
  onSetShowResults,
}: Props) {
  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => onSetShowResults(false));

  const hasResults = isNotEmpty(results);

  return (
    <SearchBoxResultsWrapper ref={wrapperRef}>
      <ErrorMessage error={error} />
      <Box shadow="shadow04">
        <LoaderWrap inContent loading={loading}>
          {results.map((result) => (
            <ResultItem
              key={result.id}
              result={result}
              onSetShowResults={onSetShowResults}
            />
          ))}
        </LoaderWrap>
        <FlexContainer
          css={{ width: "100%", justifyContent: hasResults ? "end" : "center" }}
        >
          <If condition={hasResults}>
            <Then>
              <Link
                css={{ marginTop: theme.spacing.spacing04 }}
                to={`${ROUTES.estateRecords.index}?query=${extendedEncodeURI(
                  phrase
                )}`}
                onClick={() => {
                  onSetShowResults(false);
                }}
              >
                Show All Results
              </Link>
            </Then>
            <Else>
              <Text size="lg" mb={0}>
                Sorry, there are no results.
              </Text>
            </Else>
          </If>
        </FlexContainer>
      </Box>
    </SearchBoxResultsWrapper>
  );
}

const SearchBoxResultsWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 1000;
  border: solid 1px ${theme.color.background.ui04};

  @media screen and (max-width: ${theme.breakpoints.xl}px) {
    width: calc(100% - 45px);
  }
`;
