/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useMemo } from "react";
import { Else, If, Then, When } from "react-if";
import { Spacer, theme } from "@nordcloud/gnui";
import { MetricResourceQuery } from "~/generated/graphql";
import { Pagination, ReactTable } from "~/components";
import {
  CommonListProps,
  EstateData,
  ResourcesList,
} from "~/components/EstateRecordsList";
import {
  KUBERNETES_METRICS_COLUMNS,
  PROVIDER_ICON_COLUMN,
} from "~/components/Tables/Columns";
import { useCurrency } from "~/hooks";
import { generateRowData } from "~/views/estate/helpers";

type KubernetesListProps = {
  hasMetricsResources: boolean;
  areMetricsEnabled: boolean;
  metrics: MetricResourceQuery | undefined;
  metadata: CommonListProps;
};

export function ResourcesListKubernetes({
  hasMetricsResources,
  areMetricsEnabled,
  metrics,
  metadata,
}: KubernetesListProps) {
  const { currency } = useCurrency();

  const metricColumns = useMemo(
    () => [PROVIDER_ICON_COLUMN, ...KUBERNETES_METRICS_COLUMNS],
    []
  );

  const metricsCount = metrics?.estateV2?.count ?? 0;
  const showMetricsPagination = metricsCount > 0;

  const metricDetails = useMemo(() => {
    const metricResults = metrics?.estateV2?.resources ?? [];

    return metricResults.map((res) => ({
      ...generateRowData(res, currency),
    }));
  }, [metrics, currency]);

  return (
    <If condition={hasMetricsResources && areMetricsEnabled}>
      <Then>
        <EstateData
          currentMonthTotal={metrics?.estateV2?.cost?.currentMonthTotal}
          count={metrics?.estateV2?.count}
        />
        <Spacer height={theme.spacing.spacing04} />
        <ReactTable
          striped
          columns={metricColumns}
          data={metricDetails}
          sort={false}
        />
        <When condition={showMetricsPagination}>
          <Pagination count={metricsCount} />
        </When>
      </Then>
      <Else>
        <ResourcesList metadata={metadata} />
      </Else>
    </If>
  );
}
