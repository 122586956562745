/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChartType } from "../types";

export const stackBarChartColors = [
  "#e91e63",
  "#9c27b0",
  "#3f51b5",
  "#00bcd4",
  "#009688",
  "#8bc34a",
  "#fdd835",
  "#f44336",
  "#2196f3",
  "#ff9800",
];

export const invoiceCorrectionColor = "#FFDFB2";
export const savingsPlanColor = "#CDCDD5";
export const otherCostsColor = "#676783";

export const accumulatedCostChartColors = ["#6e6e83", "#FFDFB2"];

export const costVsBudgetChartColors = {
  budgetAreaPositive: "#4caf5026",
  budgetAreaNegative: "#f4433626",
  budgetStroke: "#d32f2f",
  costOverBudget: "#f44336",
  costInBudget: "#4caf50",
  costWithoutBudget: "#6e6e83",
  costForecastPositive: "#4caf504d",
  costForecastNegative: "#f443364d",
  costForecast: "#D7D7DF",
};

export const anomaliesChartColors = {
  bar: "#6e6e83",
  barWithAnomaly: "#ff9800",
  threshold: "#ff98004d",
};

export const chartTypesWithLinks = [
  ChartType.COST_PER_CATEGORY,
  ChartType.COST_PER_ENVIRONMENT,
  ChartType.COST_PER_APPLICATION,
  ChartType.COST_PER_SERVICES,
  ChartType.COST_PER_ACCOUNT,
];
