/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { DetailData } from "~/components/Details/Detail";
import { isCloudResource, isNotEmpty, isObject } from "~/tools";
import { EstateTabsData } from "../components";
import {
  isPodsVisible,
  isRelatedResourcesForGCPVisible,
  isServicesVisible,
  isSkuVisible,
  checkProviderAndProviderType,
} from "./utils";

function getJsonObject(data: EstateTabsData): DetailData | undefined {
  const object = data.metadata.find((item) => item.label === "details");

  return isObject(object) ? object : undefined;
}

type UseGetAdvancedDataProps = {
  data: EstateTabsData;
};

export function useGetAdvancedData({ data }: UseGetAdvancedDataProps) {
  const providerType = data.estateRecord?.providerType ?? "";

  return {
    caption: ["KUBERNETES/Pod", "OPENSHIFT/Pod"].includes(providerType)
      ? "Pod Advanced Data"
      : "All Node Metadata",
    json: getJsonObject(data),
  };
}

type UseDetailsProps = {
  resourceType: string;
};

export function useDetailsMetadata({ resourceType }: UseDetailsProps) {
  if (resourceType.includes("Pod") || resourceType.includes("Node")) {
    const captionPrefix = resourceType.includes("Pod") ? "Pod" : "Node";
    return {
      heading: "Details",
      caption: `${captionPrefix} Details`,
    };
  }

  return {
    heading: "Metadata",
    caption: "Estate record metadata",
  };
}

const checkProvider = (resourceType: string) =>
  /(KUBERNETES|OPENSHIFT)/.test(resourceType ?? "");

const getKubernetesProviderTypeName = (resourceType: string) =>
  /(Pod)/.test(resourceType ?? "") ? "Pod" : "Node";

const getTagsCaption = (resourceType: string) =>
  checkProvider(resourceType)
    ? `${getKubernetesProviderTypeName(resourceType)} Labels`
    : "Tags and values";

type UseLabelsAndResourcesTabMetadataProps = {
  resourceType: string;
};

export function useLabelsAndResourcesTabMetadata({
  resourceType,
}: UseLabelsAndResourcesTabMetadataProps) {
  return {
    heading: checkProvider(resourceType) ? "Labels" : "Resource Tags",
    caption: getTagsCaption(resourceType),
  };
}

export function useGCPServices(data: EstateTabsData) {
  const estateRecord = data.estateRecord ?? {
    __typename: "CostResource",
    providerType: "",
    id: "",
    provider: undefined,
  };

  const projectResourceName = isCloudResource(estateRecord)
    ? getGcpProjectNameWithoutPrefix(estateRecord.name)
    : "";
  const accountName =
    isCloudResource(estateRecord) && estateRecord.cloudAccountId != null
      ? estateRecord.cloudAccountId
      : "";

  return {
    projectResourceName,
    accountName,
  };
}

function getGcpProjectNameWithoutPrefix(projectName: string) {
  return projectName.startsWith("project/")
    ? projectName.slice(8, projectName.length)
    : projectName;
}

export function useTabVisibility(
  data: EstateTabsData,
  isReservedInstance: boolean
) {
  const showEstateRecords = isReservedInstance;

  const showMetaDetails = isNotEmpty(data.metadata);
  const showEnrichments = isNotEmpty(data.enrichments ?? []);

  const showPods = isPodsVisible(data.estateRecord?.providerType);
  const showServices = isServicesVisible(data.estateRecord?.providerType);
  const showGCPServiceRelatedResources = isRelatedResourcesForGCPVisible(
    data.estateRecord?.providerType
  );

  const showSkus = isSkuVisible(
    data.estateRecord?.provider,
    data.estateRecord?.providerType
  );

  const isNotReservedInstance = !isReservedInstance;
  const showMetaTags = isNotReservedInstance;
  const showRelatedResources =
    isNotReservedInstance && !showGCPServiceRelatedResources;
  const showAdvancedDataTab =
    isNotReservedInstance &&
    checkProviderAndProviderType(data.estateRecord?.providerType);

  return {
    showEstateRecords,
    showMetaDetails,
    showEnrichments,
    showPods,
    showServices,
    showGCPServiceRelatedResources,
    showMetaTags,
    showRelatedResources,
    showAdvancedDataTab,
    showSkus,
  };
}
