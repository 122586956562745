/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NestedValue, UnpackNestedValue } from "react-hook-form";
import {
  WorkflowFrequency,
  WorkflowTriggerType,
  WorkflowEmailOptionsMessageType,
  Person,
} from "~/generated/graphql";
import { WorkflowExternalIntegrationType } from "~/tests/types";
import { deepClone, Maybe } from "~/tools";
import {
  SchedulerFields,
  ServiceNowFields,
  WorkflowFields,
  EmailFields,
  SlackFields,
  SlackCostsOptions,
  SlackKpiOptions,
  SlackSavingSuggestionsOptions,
} from "~/views/workflows/AddWorkflow/constants";
import { WorkflowDataType } from "./constants";

export type WorkflowFormMode = "add" | "edit" | "read";

export type WorkflowInfo = {
  id: string;
  name: string;
  active: boolean;
};

export type WorkflowCloneProps = {
  id: string;
  name: string;
  description?: Maybe<string>;
  contactPersons: Person[];
  active: boolean;
};

type SchedulerFormData = {
  [SchedulerFields.START_DATE]: string;
  [SchedulerFields.EXECUTION_TIME]: string;
  [SchedulerFields.REPEAT]: boolean;
  [SchedulerFields.FREQUENCY]: WorkflowFrequency;
  [SchedulerFields.INTERVAL]: number;
  [SchedulerFields.WEEKDAYS]: NestedValue<{ selected: boolean }[]>;
  [SchedulerFields.END_DATE]: string;
};

export type EmailFormData = {
  [EmailFields.SEND_TO_CONTACT_PERSONS]?: boolean;
  [EmailFields.SEND_TO_ADDITIONAL_USERS]?: boolean;
  [EmailFields.MAILTO]?: string;
  [EmailFields.SUBJECT]: string;
  [EmailFields.MESSAGE]: string;
  [EmailFields.MESSAGE_TYPE]: WorkflowEmailOptionsMessageType;
};

export type SlackFormData = {
  token: string;
  channel: string;
  message?: string;
  costsOptions?: SlackCostsOptionsFields;
  kpiOptions?: KpiOptionsFields;
  savingSuggestionsOptions?: SavingSuggestionsOptionsFields;
};

type SlackCostsOptionsFields = {
  [SlackCostsOptions.TOTAL_MONTHLY_COST]: boolean;
  [SlackCostsOptions.TOTAL_MONTHLY_FORECAST]: boolean;
  [SlackCostsOptions.COSTS_BY_PROVIDERS]: boolean;
  [SlackCostsOptions.COSTS_BY_CATEGORIES]: boolean;
} | null;

type KpiOptionsFields = {
  [SlackKpiOptions.COST_ALLOCATION]: boolean;
  [SlackKpiOptions.OVERALL_WASTE]: boolean;
  [SlackKpiOptions.NON_PROP_HOURS]: boolean;
} | null;

type SavingSuggestionsOptionsFields = {
  [SlackSavingSuggestionsOptions.MONTHLY_SAVINGS_BY_CATEGORIES]: boolean;
  [SlackSavingSuggestionsOptions.MONTHLY_SAVINGS_BY_POLICIES]: boolean;
  [SlackSavingSuggestionsOptions.MONTHLY_SAVINGS_BY_PROVIDERS]: boolean;
  [SlackSavingSuggestionsOptions.TOTAL_MONTHLY_SAVINGS]: boolean;
} | null;

export type WorkflowFormData = {
  name: string;
  description: string;
  contactPersonIds: NestedValue<{ value: string }[]>;
  active: boolean;
  scheduler: SchedulerFormData;
  schedulerSubmissionSnapshot?: string;
  schedulerSubmissionId: string;
  serviceNow: {
    url: string;
    username: string;
    password: string;
    shortDescription: string;
    description?: string;
    additionalMessageFields: {
      key: string;
      value: string;
    }[];
    costsOptions?: {
      totalMonthlyCost?: boolean;
      totalMonthlyForecast?: boolean;
      costsByProviders?: boolean;
      costsByCategories?: boolean;
    } | null;
    kpiOptions?: {
      costAllocation?: boolean;
      overallWaste?: boolean;
      nonProdHours?: boolean;
    } | null;
    savingSuggestionsOptions?: {
      totalMonthlySavings?: boolean;
      monthlySavingsByPolicies?: boolean;
      monthlySavingsByProviders?: boolean;
      monthlySavingsByCategories?: boolean;
    } | null;
  };
  serviceNowSubmissionSnapshot?: string;
  serviceNowSaved: boolean;
  email: EmailFormData;
  emailSubmissionSnapshot?: string;
  emailSaved: boolean;
  slack: SlackFormData;
  slackSubmissionSnapshot?: string;
  slackSaved: boolean;
  triggerComponents: WorkflowTriggerType[];
  selectDataComponent?: WorkflowDataType;
  sendDataComponents: WorkflowExternalIntegrationType[];
  savingsSuggestions: {
    applications?: string[];
    environments?: string[];
    orgUnitIds?: string[];
  };
  savingsSuggestionsSubmissionSnapshot?: string;
  kpiOptions?: {
    businessContextId: string;
  };
  costsOptions: {
    applications?: string[];
    environments?: string[];
    orgUnitIds?: string[];
  };
  anomalyCostsOptions: {
    applications: string[];
    environments: string[];
  };
  anomalyCostsOptionsSubmissionSnapshot?: string;
  costsOptionsSubmissionSnapshot?: string;
  budgetsOptions: {
    forecastGtBudget: boolean;
    costGtBudget: boolean;
    applications: string[];
    environments: string[];
    orgUnitIds: string[];
  };
  budgetsSubmissionSnapshot?: string;
};

const DEFAULT_VALUES: UnpackNestedValue<WorkflowFormData> = {
  [WorkflowFields.NAME]: "",
  [WorkflowFields.DESCRIPTION]: "",
  [WorkflowFields.CONTACT_PERSON_IDS]: [{ value: "" }],
  [WorkflowFields.ACTIVE]: true,
  [WorkflowFields.SERVICE_NOW]: {
    [ServiceNowFields.URL]: "",
    [ServiceNowFields.USERNAME]: "",
    [ServiceNowFields.PASSWORD]: "",
    [ServiceNowFields.SHORT_DESCRIPTION]: "",
    [ServiceNowFields.DESCRIPTION]: "",
    [ServiceNowFields.ADDITIONAL_MESSAGE_FIELDS]: [],
    [ServiceNowFields.COSTS_OPTIONS]: null,
    [ServiceNowFields.KPI_OPTIONS]: null,
    [ServiceNowFields.SAVING_SUGGESTIONS_OPTIONS]: null,
  },
  serviceNowSaved: false,
  [WorkflowFields.SERVICE_NOW_SUBMISSION_SNAPSHOT]: undefined,
  [WorkflowFields.EMAIL]: {
    [EmailFields.SEND_TO_CONTACT_PERSONS]: false,
    [EmailFields.SEND_TO_ADDITIONAL_USERS]: false,
    [EmailFields.MAILTO]: undefined,
    [EmailFields.SUBJECT]: "",
    [EmailFields.MESSAGE]: "",
    [EmailFields.MESSAGE_TYPE]: WorkflowEmailOptionsMessageType.Custom,
  },
  emailSaved: false,
  emailSubmissionSnapshot: undefined,
  [WorkflowFields.SLACK]: {
    [SlackFields.TOKEN]: "",
    [SlackFields.CHANNEL]: "",
    [SlackFields.MESSAGE]: "",
    [SlackFields.COSTS_OPTIONS]: null,
    [SlackFields.KPI_OPTIONS]: null,
    [SlackFields.SAVING_SUGGESTIONS_OPTIONS]: null,
  },
  slackSaved: false,
  slackSubmissionSnapshot: undefined,
  [WorkflowFields.SCHEDULER]: {
    [SchedulerFields.START_DATE]: "",
    [SchedulerFields.EXECUTION_TIME]: "",
    [SchedulerFields.REPEAT]: false,
    [SchedulerFields.FREQUENCY]: WorkflowFrequency.Daily,
    [SchedulerFields.INTERVAL]: 1,
    [SchedulerFields.WEEKDAYS]: [
      { selected: true },
      { selected: true },
      { selected: true },
      { selected: true },
      { selected: true },
      { selected: false },
      { selected: false },
    ],
    [SchedulerFields.END_DATE]: "",
  },
  schedulerSubmissionId: "",
  [WorkflowFields.SCHEDULER_SUBMISSION_SNAPSHOT]: undefined,
  [WorkflowFields.TRIGGER_COMPONENTS]: [],
  [WorkflowFields.SELECT_DATA_COMPONENT]: undefined,
  [WorkflowFields.SEND_DATA_COMPONENTS]: [],
  savingsSuggestions: {
    applications: undefined,
    environments: undefined,
    orgUnitIds: undefined,
  },
  kpiOptions: undefined,
  costsOptions: {
    applications: undefined,
    environments: undefined,
    orgUnitIds: undefined,
  },
  budgetsOptions: {
    forecastGtBudget: true,
    costGtBudget: true,
    applications: [],
    environments: [],
    orgUnitIds: [],
  },
  budgetsSubmissionSnapshot: undefined,
  anomalyCostsOptionsSubmissionSnapshot: undefined,
  [WorkflowFields.ANOMALY_COSTS_OPTIONS]: {
    applications: [],
    environments: [],
  },
};

export function isDefaultEmail(
  email: Record<string, unknown>
): email is WorkflowFormData["email"] {
  return (
    email.mailTo === DEFAULT_VALUES.email.mailTo &&
    email.message === DEFAULT_VALUES.email.message &&
    email.messageType === DEFAULT_VALUES.email.messageType &&
    email.sendToContactPersons === DEFAULT_VALUES.email.sendToContactPersons &&
    email.subject === DEFAULT_VALUES.email.subject
  );
}

export const getDefaultValues = () => deepClone(DEFAULT_VALUES);
